.Link-letter {
  width: 50px;
  height: 50px;
  font-size: 40px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  border: 2px #61dafb solid;
  margin-left: -2px;
}

.Link.active .Link-letter {
  border-color: rgb(247, 240, 47);
}

.Link-solve,
.Link-add-letter {
  margin-left: 2px;
  width: 54px;
}

button.Link-add-letter {
  border-color: lightgoldenrodyellow;
}

button.Link-solve {
  border-color: greenyellow;
}

.Link {
  display: flex;
  margin: 4px;
  font-size: 40px;
}