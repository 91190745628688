.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App input,
.App button {
  font-size: 40px;
  border: 2px solid;
  margin: 2px;
  color: white;
  background-color: transparent;
}

.App button {
  text-transform: uppercase;
}

.App button {
  height: 54px;
}

.App input {
  height: 48px;
}
