.Score {
  border: 2px solid transparent;
  padding: 10px;
}

.Score.active {
  border-color: white;
}

.Score > h3 {
  margin-top: 0px;
  margin-bottom: 0px;
}